import crypto from "crypto-js";
import type { AnalyticsBrand, AnalyticsProduct } from "~/types/analytics";
import type { ApiCartSummaryItem } from "~/types/cart";

/**
 * Algolia - GA User ID
 * Extracts the user ID from the Google Analytics device ID.
 * @example `GA1.1.xxxxxxxxxx.xxxxxxxxxx => xxxxxxxxxx_xxxxxxxxxx`
 */

const extractGoogleAnalyticsUserIdFromCookie = (gaCookie: string) => {
  if (gaCookie) {
    // Remove the Google Analytics tracker from the device ID.
    const userIdParts = gaCookie.split(".").slice(-2);
    if (userIdParts.length === 2) {
      return userIdParts.join("_");
    }
  }
  return undefined;
};

const getBrowserCookie = (cookieName: string) => {
  // In React Native environments, `document.cookie` doesn't exist.
  if (typeof document !== "object" || typeof document.cookie !== "string") {
    return undefined;
  }
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
};

/**
 * Returns the Google Analytics User ID from a browser cookie name.
 * @example `getGoogleAnalyticsUserIdFromBrowserCookie('_ga')`
 */
export const getGoogleAnalyticsUserIdFromBrowserCookie = (
  cookieName: string,
) => {
  const browserCookie = getBrowserCookie(cookieName);

  if (!browserCookie) {
    return undefined;
  }

  return extractGoogleAnalyticsUserIdFromCookie(browserCookie);
};

/**
 * Helper function to generate a user token for Google Analytics.
 * @returns The generated user token.
 */
const generateGAUserToken = () => {
  const generateRandomToken = crypto.lib.WordArray.random(16);

  return (
    getGoogleAnalyticsUserIdFromBrowserCookie("_ga") ??
    generateRandomToken.toString(crypto.enc.Hex)
  );
};

/**
 * Updates the Google Analytics token saved in local storage to latest value
 * @param gtm Google Tag Manager instance
 * @returns The updated user token.
 */
export const updateGAUserToken = (gtm: unknown): string => {
  const gaUserToken = generateGAUserToken();
  if (gaUserToken) {
    const sessionStorageToken = getDecryptedSessionStorage("_abbga_");
    if (sessionStorageToken !== gaUserToken) {
      setEncryptedSessionStorage("_abbga_", gaUserToken);
      // track event
      gtm?.trackEvent({
        algoliaUserToken: gaUserToken,
      });
    }
  }
  return gaUserToken;
};

/**
 * Converts cart item data for google analytics ecommerce event.
 *
 * @param items - The array of cart summary items.
 * @param brand - The brand for the analytics data (residential or business).
 * @returns An array of analytics products.
 */
export const getAnalyticsCartItems = (
  items: ApiCartSummaryItem[],
  brand: AnalyticsBrand,
): AnalyticsProduct[] => {
  return items.flatMap((item) => {
    if (item.type == "promo" || item.type == "shipping") {
      return [];
    }
    return [
      {
        item_brand: brand, // residential | business
        item_category: item.type.toLowerCase().replace(" ", "_"),
        item_name: item.description,
        item_id: item.plan_ref ?? item.id,
        item_variant: "",
        quantity: item.quantity,
        price: Number(formatPrice(item.unit_cost)),
        coupon: items.find(
          (x) => x.type === "promo" && x.parent?.id === item.id,
        )?.id,
        cost_type: item.cost_type, // "monthly" | "once"
        months: item.months, // number | null
      },
    ];
  });
};
